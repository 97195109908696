import "./App.css";
import Layout from "./components/Layout";
import { Route, Routes } from "react-router-dom";
import Blogs from "./pages/Blogs";
import Main from "./pages/Main";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/blogs" element={<Blogs />} />
      </Routes>
    </Layout>
  );
}

export default App;
