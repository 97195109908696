import React from "react";

export default function Blogs() {
  return (
    <div
      style={{
        height: "100vh",
        padding: "20px",
        fontSize: "20px",
        textAlign: "center",
        backgroundColor: "aliceblue",
      }}
    >
      Coming soon! 
    </div>
  );
}
