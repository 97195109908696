import React from "react";
import "./Hero.css";
import bio from "../bio";

export default function Hero() {
  const stacks = bio.stacks.map((item) => {
    return <li className="hero-stacks">{item}</li>;
  });

  return (
    <div className="hero">
      <div className="hero-bio">
        <div className="hero-bio-text">
          <h2>Hello, I'm Jenni</h2>
        </div>
      </div>
      <div className="hero-outer-card">
        <div className="hero-card">
          <div className="hero-grid">
            <div className="hero-grid-1">
              <div className="hero-img">
                <img
                  src="../profile.jpg"
                  alt="Profile of Jenny"
                  className="hero-photo"
                />
              </div>
              <h3
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  color: "rgb(15, 67, 113)",
                }}
              >
                {bio.name}
              </h3>
              <h5 style={{ marginTop: "5px" }}>{bio.title}</h5>
              <div className="hero-btn">
                <button className="hero-btn-email">
                  <a href={bio.email}>
                    <i
                      class="fa-solid fa-envelope"
                      style={{ marginRight: "20px" }}
                    ></i>
                    Email
                  </a>
                </button>
              </div>
            </div>
            <div className="hero-grid-2">
              <h6 className="hero-subheading" style={{ marginTop: "15px" }}>
                About
              </h6>
              <p className="hero-content">{bio.bio}</p>
              <p className="hero-content">{bio.anotherbio}</p>
              <h6 className="hero-subheading" style={{ marginTop: "10px" }}>
                Tech Stacks
              </h6>
              <ul className="hero-content-ul">{stacks}</ul>
              <h6 className="hero-subheading" style={{ marginTop: "10px" }}>
                Interests
              </h6>
              <p className="hero-content">{bio.interest}</p>
            </div>
          </div>
          <ul className="hero-icons">
            <li>
              <a href={bio.github} target="_blank" rel="noreferrer">
                <i class="fa-brands fa-github"></i>
              </a>
            </li>
            <li>
              <a href={bio.linkedIn} target="_blank" rel="noreferrer">
                <i class="fa-brands fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href={bio.twitter} target="_blank" rel="noreferrer">
                <i class="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href={bio.email}>
                <i class="fa-solid fa-envelope"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
