import React from "react";
import "./Project.css";

export default function Projects(props) {
  const tools = props.tools.map((tool) => {
    return <li className="project-tools-list">{tool}</li>;
  });
  return (
    <div className="project">
      <div>
        <h3 className="project-title">{props.title}</h3>
        <div style={{ padding: "10px" }}>
          <div className="project-img-border">
            <img
              src={props.imgUrl}
              alt="project screenshot"
              className="project-img"
            />
          </div>
          <div className="project-body">
            <p style={{ marginTop: "20px" }}>{props.description}</p>
            <h4
              style={{
                marginTop: "5px",
                marginBottom: "0",
                color: "rgb(15, 67, 113)",
              }}
            >
              Tools
            </h4>
            <ul className="project-tools">{tools}</ul>
            <div className="project-btn">
              <button className="">
                <a href={props.projectUrl} target="_blank" rel="noreferrer">
                  View
                  <i
                    class="fa-solid fa-arrow-up-right-from-square"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
