import React from "react";
import "./Divider.css";

export default function Divider() {
  return (
    <div className="divider">
      <span id="projects" class="absolute -top-60"></span>
      <h3>Projects</h3>
      <hr className="divider-hr" />
    </div>
  );
}
