const projects = [
  {
    id: 1,
    title: "DukanChua",
    description: "An intuitive online shopping platform for a range of items.",
    date: "15 June 2022",
    imgUrl: "../dukanchua.png",
    projectUrl: "https://magnificent-fudge-164a8b.netlify.app/",
    tools: ["JavaScript", "React", "HTML", "CSS", "ESLint", "Netlify"],
  },
  // {
  //   id: 2,
  //   title: "Riompa Engineering Limited Website",
  //   description: "A website showcasing the company's services and projects.",
  //   date: "15 June 2022",
  //   imgUrl: "../riompa.png",
  //   projectUrl: "https://riompaengineering.com/",
  //   tools: ["PHP", "JavaScript", "HTML", "CSS", "TailwindCSS", "Figma"],
  // },
  {
    id: 3,
    title: "TICK-E Bus Booking App",
    description: "An online Bus Booking application built with PHP/Laravel.",
    date: "15 June 2023",
    imgUrl: "../ticke.png",
    projectUrl: "https://ticke.cjelevate.com/",
    tools: [
      "PHP/Laravel",
      "JavaScript",
      "HTML",
      "TailwindCSS",
      "Figma",
      "DigitalOcean",
    ],
  },
];

export default projects;
