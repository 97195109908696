import React from "react";
import "./Footer.css";
import bio from "../bio";

export default function Footer() {
  return (
    <footer>
      <ul className="footer-icons">
        <li>
          <a href={bio.github} target="_blank" rel="noreferrer">
            <i class="fa-brands fa-github"></i>
          </a>
        </li>
        <li>
          <a href={bio.linkedIn} target="_blank" rel="noreferrer">
            <i class="fa-brands fa-linkedin"></i>
          </a>
        </li>
        <li>
          <a href={bio.twitter} target="_blank" rel="noreferrer">
            <i class="fa-brands fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href={bio.email}>
            <i class="fa-solid fa-envelope"></i>
          </a>
        </li>
      </ul>
      <hr
        style={{ backgroundColor: "aliceblue", height: "1px", border: "none" }}
      />
      <small>© 2024 All Rights Reserved</small>
    </footer>
  );
}
