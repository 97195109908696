import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

export default function Header() {
  return (
    <header>
      <div>
        <h4>JƏИNİ</h4>
      </div>
      <div className="header-links">
        <ul>
          <li><a href="/">About</a></li>
          <li><a href="/#projects">Projects</a></li>
          <li><Link to="/blogs">Blogs</Link></li>
        </ul>
      </div>
    </header>
  );
}
