import React from "react";
import Hero from "../components/Hero";
import Divider from "../components/Divider";
import Projects from "../components/Project";
import project from "../project";

export default function Main() {
  const projects = project.map((item) => {
    return (
      <Projects
        key={item.id}
        id={item.id}
        imgUrl={item.imgUrl}
        title={item.title}
        description={item.description}
        tools={item.tools}
        projectUrl={item.projectUrl}
      />
    );
  });
  return (
    <div>
      <Hero />
      <Divider />
      <div className="app-projects">
        {projects}
      </div>
    </div>
  );
}
