const bio = {
  id: 1,
  name: "Jennifer Oryema Apiyo",
  bio: `A Software Engineer with experience in delivering high-quality and efficient products that not only meet users' needs but also adhere to industry standards. I hold a strong appreciation for the value of time, excellence, and continuous learning in my work.`,
  anotherbio:
    "My profound understanding of software application architectures enables me to design robust and reliable solutions that cater to the complexities of modern software development.",
  interest: "Music | Art | Decor | Cooking | Baking",
  title: "Software Engineer",
  stacks: [
    "Languages (Python/Django | JavaScript/React/TypeScript | PHP/Laravel | Flutter/Dart)",
    "Version Control (Git | GitHub)",
    "Databases (PostgreSQL | MySQL | MongoDB)",
    "Tests (Pytest | Jest | Cypress)",
    "Others (C | HTML | CSS | TailwindCSS | Docker)",
  ],
  socials: ["Github", "LinkedIn", "Twitter"],
  resume: "",
  github: "https://github.com/APIYOJENNIFER",
  linkedIn: "https://linkedin.com/in/apiyojenniferoryema",
  twitter: "https://twitter.com/jennifer_apiyo",
  email: "mailto:jenniferoryema@gmail.com",
};
export default bio;
